import { useState } from "react";
import {
  Typography,
  Box,
  Divider,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Grid,
  MenuItem,
  Link,
  Hidden,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
// import { Circle } from '@mui/icons-material/Circle';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ModuleResultTable from "./ModuleResultTable";
import moment from "moment";
import DetailModal from "../../CourseTemplateDetails/DetailModal";


const CourseDetailDisplay = ({ courseData }) => {
  const {
    courseName,
    startDateTime,
    endDateTime,
    generalScore,
    lastActiveDateTime,
    isActive,
    isStarted,
    courseId,
    isFinished,
    isSuccess,
    modules,
    score
  } = courseData;
  

  const [open, setOpen] = useState(false);
  const startDate = moment(startDateTime).format("DD.MM.YYYY");
  const endDate = moment(endDateTime).format("DD.MM.YYYY");
  const date = startDate + " - " + endDate;
  const moduleNames = courseData?.modules
    ?.map((module) => module.name)
    .join(",  ");

    const renderDate = (dateString) => {
      const date = new Date(dateString);
      if (date.getTime() === 0 || isNaN(date.getTime())) {
        return "-";
      }
      return date.toLocaleDateString("no-NO");
    };
  
  // function getScore()
  // {
  //   if(courseData.modules.length <= 0)
  //     return "-";
  //   const increments = 1;
  //  
  //   if(courseData.modules.)
  // }

  // function getSubmoduleScores() {
  //   let increments = 0;
  //   let current = 0;
  //  
  //   return 5;
  //  
  //   // Check if the course has modules to iterate through
  //   if (modules) {
  //     modules.forEach(module => {
  //       // Loop through each submodule in the module
  //       Object.keys(module.submodules).forEach(submoduleKey => {
  //         // if (submoduleKey === 'teori') {
  //         //   // Skip 'teori'
  //         //   return;
  //         // }
  //        
  //         const submodule = module.submodules[submoduleKey];
  //
  //         // Check if the submodule has a score property
  //         if (submodule && submodule.score !== undefined) {
  //           increments += 1;
  //           current += submodule.Score;
  //         }
  //       });
  //     });
  //   }
  //
  //   return 5;
  //  
  //   return increments > 0 ? current / increments : 0;
  // }
 
  function getStatusInfo(status) {
    if (status=="Godkjent") {
      return { color: "green" };
    } else if (status=="Påbegynt") {
      return {  color: "yellow" };
    } else if (status=="Ikke godkjent") {
      return {  color: "red" };
    } else if (status=="Ikke påbegynt") {
      return {  color: "gray" };
    } else {
      return { status: "Ukjent", color: "gray" };
    }
  }

  // ! Create a separate CourseDetails component for each course
  if (!courseData?.courseName) {
      return; // This will prevent the component from rendering anything if courseName is null or undefined
  }
  
  const modulDetails = courseData?.modules
      ? courseData.modules.map((modul, i) => (
          <ModuleResultTable key={i} modulDetails={modul} />
        ))
      : [];
  

  function RowItem({ data, label, icon: Icon, statusColor, subcontent }) {
    return (
      <>
        <MenuItem sx={{ fontWeight: "600", color: "#858585" }}>
          {label}
        </MenuItem>
        <MenuItem sx={{ display: "flex", alignItems: "center", fontSize: 14 }}>
          {Icon && (
            <Icon sx={{ marginRight: 1, color: statusColor }} fontSize="10" />
          )}
          {/* render the icon only if it's defined */}
          <span>{data?.length > 25 ? data?.slice(0, 25) + "..." : data}</span>
        </MenuItem>
        {subcontent && (
          <Link
            sx={{
              fontSize: 13,
              textDecoration: "none",
              cursor: "pointer",
              color: "#0091ea",
              ml: 1.5,
            }}
            onClick={handleOpen}
          >
            {subcontent}
          </Link>
        )}
      </>
    );
  }

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Grid container sx={{ mb: 4 }}>
      <Box
        sx={{
          p: 2.5,
          minWidth: 1100,
          border: 1,
          borderColor: "#e0e0e0",
          borderRadius: 2,
          backgroundColor: "#fff",
          boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)'

        }}
      >
        <Grid container>
          <Grid item xs={5} sm={5}>
            <Chip
              label={isActive ? "Aktiv" : "Inaktiv"}
              sx={{
                marginLeft: 2,
                backgroundColor: courseData?.isActive ? "#00c853" : "#bdbdbd",
                color: "#fff",
              }}
            />
          </Grid>
          <Grid item xs={5} sm={5}>
            <Typography
              sx={{ fontWeight: "bold", fontSize: 17 }}
              color='primary.dark'
            >
              {courseName}
            </Typography>
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 2 }}>
          <Grid item xs={8} sm={6} md={3} lg={3} xl={3}>
            <RowItem label="Periode" data={date} />
          </Grid>
          <Hidden lgDown>
            <Divider orientation="vertical" flexItem sx={{ height: 70, mr:2 }} />
          </Hidden>
          <Grid item xs={8}  sm={6} md={2} lg={1} xl={1}>
            <RowItem label="Skår" data={(score * 100).toFixed(2) + "%"} />
          </Grid>
          <Hidden lgDown>
            <Divider orientation="vertical" flexItem sx={{ height: 70, mr:2 }} />
          </Hidden>
          <Grid item xs={8}  sm={6} md={2} lg={3} xl={2}>
            <RowItem
              label="Status"
              data={courseData?.status}
              icon={CircleIcon}
              statusColor={
                getStatusInfo(courseData?.status).color
              }
            />
          </Grid>
          <Hidden lgDown>
            <Divider orientation="vertical" flexItem sx={{ height: 70, mr:2 }} />
          </Hidden>
          <Grid item xs={8}  sm={6} md={3} lg={3} xl={3}>
            <RowItem
              label="Moduler"
              data={moduleNames || "-"}
              subcontent={"Se mer info"}
            />
          </Grid>
          <Hidden lgDown>
            <Divider orientation="vertical" flexItem sx={{ height: 70, mr:2 }} />
          </Hidden>
          <Grid item xs={4}  sm={4} md={2} lg={1} xl={1}>
            <RowItem label="Sist Aktiv" data={renderDate(lastActiveDateTime)} />
          </Grid>
        </Grid>
        <Accordion sx={{ bgcolor: " #fafafa",  mt:2 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              "& .MuiAccordionSummary-content": {
                flexGrow: 0,
              },
            }}
          ></AccordionSummary>
          <AccordionDetails>
            <>{modulDetails}</>
          </AccordionDetails>
        </Accordion>
      </Box>

      <DetailModal
        onClose={() => setOpen(false)}
        open={open}
        courseId={courseId}
      />
    </Grid>
  );
};

export default CourseDetailDisplay;