import {React, useState, useEffect} from "react";
import {
    Collapse,
    Checkbox,
    TextField,
    Autocomplete,
    styled, FormHelperText,
    lighten, Grid, FormControl
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {GetAllUsers} from "../../../api/endpoints";
import apiClient from "../../../api/apiClient";

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;

export default function UserSelect({handleUserSelect, formSubmit, reset}) {
    const [userData, setUserData] = useState([]);
    const [selectedBox, setSelectedBox] = useState([])
    const [collapsedGroups, setCollapsedGroups] = useState({}); // State to track collapsed groups
    // const [formValid, setFormValid] = useState(false);

    // ! API call to get departments and workers

    useEffect(() => {
        (async () => {
            try {
                const departmentUsers = await apiClient.get(GetAllUsers);
                setUserData(departmentUsers.data.data);

                // Initialize all groups as collapsed
                const flattenedUsers = departmentUsers.data.data.reduce(
                    (acc, {user}) => acc.concat(user),
                    []
                );
                const options = flattenedUsers.map(({departmentId}) => departmentId);
                const uniqueGroups = Array.from(new Set(options)); // Get unique department names
                const initialCollapsedState = uniqueGroups.reduce((acc, group) => {
                    acc[group] = true; // Start collapsed
                    return acc;
                }, {});
                setCollapsedGroups(initialCollapsedState);

            } catch (err) {
                console.log(err);
            }
        })();
    }, []);


    // ! Organise the JSON data

    const flattenedUsers = userData.reduce(
        (acc, {user}) => acc.concat(user),
        []
    );
    const options = flattenedUsers.map(({name, surname, departmentId, userId}) => ({
        name: `${name} ${surname}`,
        departmentName: `${departmentId}`,
        id: `${userId}`
    }));


    // ! Handle Selected Departments and Users

    const checkOption = (option) => {
        const check = selectedBox?.some((item) => item.id === option.id);
        return check
    }

    const checkGroup = (group) => {
        const groupLength = options.filter((d) => d.departmentName === group).length;
        const selectedGroupLength = selectedBox.filter(
            (d) => d.departmentName === group
        ).length;
        return groupLength === selectedGroupLength;
    };

    const selectGroup = (group) => {
        const groupedUsers = options.filter((d) => d.departmentName === group);
        const selectedDepartments = selectedBox.filter(
            (d) => d.departmentName === group
        );

        if (selectedDepartments.length > 0 && selectedDepartments.length < groupedUsers.length) {
            // If the group isn't toggled but at least one child is, select all elements
            setSelectedBox((prevState) => [
                ...prevState.filter((d) => d.departmentName !== group), // Remove existing group selections
                ...groupedUsers // Add all users in the group
            ]);
        } else if (selectedDepartments.length === groupedUsers.length) {
            // If the group is toggled (all elements are selected), deselect all elements
            setSelectedBox((prevState) => prevState.filter((d) => d.departmentName !== group));
        } else {
            // If no child elements are selected, select all elements
            setSelectedBox((prevState) => [...prevState, ...groupedUsers]);
        }
    }

    const toggleGroup = (group) => {
        setCollapsedGroups((prevState) => ({
            ...prevState,
            [group]: !prevState[group] // Toggle the collapsed state for the group
        }));
    };

    useEffect(() => {
        handleUserSelect(selectedBox)
        // setFormValid(selectedBox.length > 0);
    }, [selectedBox]);

    useEffect(() => {
        setSelectedBox([]);
    }, [reset]);


    // ! Style Group Header in Autocomplete
    const GroupHeader = styled("div")(({theme}) => ({
        top: "-8px",
        padding: "4px 10px",
        color: theme.palette.primary.main,
        backgroundColor: lighten(theme.palette.primary.light, 0.85),
        display: "flex",
        // justifyContent: "flex-start",
        justifyContent: "space-between",
        alignItems: "center",
        cursor: "pointer" // Make the header clickable
    }));

    return (
        <Grid item xs={12}>
            <FormControl
                variant="standard"
                fullWidth
                required
                error={reset === false && formSubmit && selectedBox.length === 0}
            >
                <Autocomplete
                    multiple
                    fullWidth
                    disableCloseOnSelect
                    value={selectedBox}
                    onChange={(_, option) => {
                        setSelectedBox([...option]);
                        handleUserSelect(selectedBox)
                    }}
                    options={options}
                    groupBy={(option) => option.departmentName}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) => option.name === value.name}
                    onInputChange={(_, value) => {

                        // if (value.trim() === "") {
                        //   // Reset all groups to collapsed when the search field is cleared
                        //   setCollapsedGroups((prevState) => {
                        //     const resetState = {...prevState};
                        //     Object.keys(resetState).forEach((group) => {
                        //       resetState[group] = true; // Collapse all groups
                        //     });
                        //     return resetState;
                        //   });
                        // }
                        if (value.trim() !== "") {
                            // Check which groups contain matches
                            const matchingGroups = options
                                .filter((option) =>
                                    option.name.toLowerCase().includes(value.toLowerCase())
                                )
                                .map((option) => option.departmentName);

                            // Update `collapsedGroups` to expand matching groups
                            setCollapsedGroups((prevState) => {
                                const newCollapsedState = {...prevState};
                                Object.keys(newCollapsedState).forEach((group) => {
                                    newCollapsedState[group] = !matchingGroups.includes(group);
                                });
                                return newCollapsedState;
                            });
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Velg deltakere"
                            variant="standard"
                            placeholder="Legg til deltakere"
                            required
                            error={reset === false && formSubmit && selectedBox.length === 0}
                        />
                    )}
                    renderOption={(props, option) => (
                        <li {...props} key={option.id}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                checked={checkOption(option)}
                            />
                            {option.name}
                        </li>
                    )}
                    renderGroup={(params, index) => (
                        <div key={params.group + '-' + index}>
                            <GroupHeader onClick={() => toggleGroup(params.group)}>
                                <div>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        checked={checkGroup(params.group)}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            selectGroup(params.group)
                                        }}
                                    />
                                    {params.group}
                                </div>
                                <span>{collapsedGroups[params.group] ? "+" : "-"}</span>
                            </GroupHeader>
                            <Collapse in={!collapsedGroups[params.group]}>
                                {params.children}
                            </Collapse>
                        </div>
                    )}
                />
                {reset === false && formSubmit && selectedBox.length === 0 &&
                    <FormHelperText sx={{color: 'red'}}>Dette feltet er påkrevd</FormHelperText>}
            </FormControl>
        </Grid>
    );
}
